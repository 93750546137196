import { createWebHistory, createRouter } from "vue-router";

// Layouts
import WebLayout from "@/components/layouts/WebLayout.vue";

// Web
import Home from "@/components/web/pages/Home.vue";
import Services from "@/components/web/pages/Services.vue";
import Contact from "@/components/web/pages/Contact.vue";
import Company from "@/components/web/pages/Company.vue";
import PrivacyPolicy from "@/components/web/pages/PrivacyPolicy.vue";
import LegalAdvice from "@/components/web/pages/LegalAdvice.vue";
import CookiesPolicy from "@/components/web/pages/CookiesPolicy.vue";

const routes = [
	{
		path: "/",
		component: WebLayout,
		name: "home",
		meta: {
			title: "Inicio",
		},
		children: [
			{
				path: "",
				component: Home,
				name: "home",
				meta: {
					title: "Inicio – Librería e imprenta Rivero",
				},
			},
			{
				path: "servicios",
				component: Services,
				name: "services",
				meta: {
					title: "Servicios – Librería e imprenta Rivero",
				},
			},
			{
				path: "contacto",
				component: Contact,
				name: "contact",
				meta: {
					title: "Contacto – Librería e imprenta Rivero",
				},
			},
			{
				path: "empresa",
				component: Company,
				name: "company",
				meta: {
					title: "Empresa – Librería e imprenta Rivero",
				},
			},
			{
				path: "politica-de-privacidad",
				component: PrivacyPolicy,
				name: "privacy-policy",
				meta: {
					title: "Política de Privacidad",
				},
			},
			{
				path: "aviso-legal",
				component: LegalAdvice,
				name: "legal-advice",
				meta: {
					title: "Aviso legal",
				},
			},
			{
				path: "politica-de-cookies",
				component: CookiesPolicy,
				name: "cookies-policy",
				meta: {
					title: "Política de cookies",
				},
			},
		],
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/",
	},
];

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// Get the page title from the route meta data that we have defined
	// See further down below for how we setup this data
	const title = to.meta.title;

	//Take the title from the parameters
	const titleFromParams = to.params.pageTitle;
	// If the route has a title, set it as the page title of the document/page
	if (title) {
		document.title = title;
	}
	// If we have a title from the params, extend the title with the title
	// from our params
	if (titleFromParams) {
		document.title = `${titleFromParams} - ${document.title}`;
	}
	// Continue resolving the route
	next();
});

export default router;
