<template>
	<DataTable
		v-model:selection="selectedIds"
		stripedRows
		:value="list"
		:totalRecords="total"
		:rowHover="datatableDefaults.rowHover"
		:rows="datatableDefaults.rows"
		:rowsPerPageOptions="datatableDefaults.rowsPerPageOptions"
		:currentPageReportTemplate="datatableDefaults.currentPageReportTemplate"
		:paginator="datatableDefaults.paginator"
		:paginatorTemplate="datatableDefaults.paginatorTemplate"
		:loading="datatableDefaults.loading"
		:lazy="datatableDefaults.lazy"
		:responsiveLayout="datatableDefaults.responsiveLayout"
		:breakpoint="datatableDefaults.breakpoint"
		@page="changeCurrentPage($event)"
		@row-click="showRegister($event, 'show')"
		@row-reorder="onRowReorder($event)"
		@sort="sort($event)"
	>
		<template #header>
			<div class="row gy-3">
				<div v-if="showTitle">
					<h4
						v-if="
							currentRouteName != null &&
							showCurrentRouteName &&
							customTitle == null
						"
						class="text-primary fw-bold"
					>
						{{ currentRouteName }}
					</h4>
					<h4 v-else class="text-primary fw-bold">
						{{ customTitle }}
					</h4>
				</div>
				<div
					class="col-12"
					:class="{
						'col-md-8': showSelectMultipleColumn || showAddButton,
					}"
				>
					<TableFilters :filters="filters" />
				</div>
				<div
					v-if="showAddButton || selectedIds.length > 0"
					class="col-12 col-md-4"
				>
					<div
						class="action-buttons d-flex flex-wrap gap-3 align-items-center justify-content-md-end pb-1"
					>
						<Button
							v-if="showAddButton"
							:label="addButtonText"
							class="bg-yellow border-0 text-white"
							@click="addRegister()"
						/>
						<Button
							v-if="selectedIds.length > 0"
							label="Borrar seleccionados"
							class="bg-primary text-white"
							icon="pi pi-trash"
							@click="deleteCheckedRegisters()"
						/>
						<slot name="custom-actions"></slot>
					</div>
				</div>
			</div>
		</template>

		<template #empty>{{ datatableDefaults.noResults }}</template>

		<Column
			v-if="rowReorder"
			rowReorder
			headerStyle="width: 3rem"
			:reorderableColumn="false"
			header="Reordenar"
		/>

		<template #loading>
			<LoadingTable />
		</template>

		<Column
			v-if="showSelectMultipleColumn"
			class="select-multiple"
			selectionMode="multiple"
			headerStyle="width: 3em"
		></Column>

		<slot name="columns"></slot>

		<Column v-if="showActionsColumn" header="Acciones">
			<template #body="slotProps">
				<div class="d-flex align-items-center gap-2">
					<Button
						class="border-primary bg-primary"
						icon="pi pi-pencil"
						@click="showRegister(slotProps.data, 'edit')"
					/>
					<Button
					v-if="showDeleteButton"
						class="border-primary bg-primary"
						icon="pi pi-trash"
						@click="deleteRegister(slotProps.data.id)"
					/>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<script>
	import DataTable from "primevue/datatable";
	import Column from "primevue/column";

	import LoadingTable from "@/components/partials/tables/LoadingTable.vue";
	import TableFilters from "@/components/partials/tables/TableFilters.vue";

	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			TableFilters,
			DataTable,
			Column,
			LoadingTable,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
			list: {
				type: Array,
				default: () => [],
			},
			total: {
				type: Number,
				default: 0,
			},
			filters: {
				type: Array,
				default: () => [],
			},
			delete: {
				type: String,
			},
			showAddButton: {
				type: Boolean,
				default: true,
			},
			addButtonText: {
				type: String,
				default: "Añadir registro",
			},
			showEditButton: {
				type: Boolean,
				default: true,
			},
			showDeleteButton: {
				type: Boolean,
				default: true,
			},
			showActionsColumn: {
				type: Boolean,
				default: true,
			},
			showCurrentRouteName: {
				type: Boolean,
				default: true,
			},
			reloadByFilters: {
				type: Boolean,
				default: true,
			},
			additionalParams: {
				type: Object,
				default: () => ({}),
			},
			customTitle: {
				type: String,
				default: null,
			},
			rowReorder: {
				type: Boolean,
				default: false,
			},
			showSelectMultipleColumn: {
				type: Boolean,
				default: true,
			},
			showTitle: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				selectedIds: [],
				timeout: null,
				lastPageChange: null,
			};
		},
		methods: {
			...mapActions(["getRegisters", "deleteRegisters"]),
			...mapMutations([
				"changeCurrentTablePage",
				"changeCurrentTableSort",
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
			]),
			getList(event = null) {
				this.getRegisters({
					route: this.route,
					stateVariable: this.stateVariable,
					page: event?.page,
					rows: event?.rows,
					additionalParams: this.additionalParams,
				});
			},
			changeCurrentPage(event) {
				this.lastPageChange = event;

				if (event != null) {
					this.changeCurrentTablePage({
						stateVariable: this.stateVariable,
						event,
					});
				}

				this.getRegisters({
					route: this.route,
					stateVariable: this.stateVariable,
					page: event?.page,
					rows: event?.rows,
					additionalParams: this.additionalParams,
				});

				this.$emit("changeCurrentPage", event);
			},
			addRegister() {
				this.toggleFormDialog({
					stateVariable: this.stateVariable,
					show: true,
				});

				this.$emit("addRegister");
			},
			showRegister(e, type) {
				const classList = e.originalEvent?.target.classList;

				if (
					(classList == undefined ||
						!classList?.contains("download-link")) &&
					!classList?.contains("p-checkbox-icon")
				) {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});

					this.toggleFormDialog({
						stateVariable: this.stateVariable,
						show: true,
					});

					this.$emit("showRegister", register, type);
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.delete}/${id}`,
						}).then(() => {
							this.getList(this.lastPageChange);
							this.$emit("getList", this.lastPageChange);
						});
					},
				});
			},
			deleteCheckedRegisters() {
				let ids = this.$helper.pushIdsToArray(
					this.selectedIds,
					this.lastPageChange != null
						? this.lastPageChange.rows
						: this.datatableDefaults.rows
				);

				this.$confirm.require({
					message: "¿Desea borrar los registros seleccionados?",
					header: "Borrar registros",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.delete}-multiple`,
							ids,
						}).then(() => {
							this.getList(this.lastPageChange);

							this.$emit("getList", this.lastPageChange);
							this.selectedIds = [];
						});
					},
				});
			},
			onRowReorder(event) {
				this.$emit("onRowReorder", event);
			},
			sort(event) {
				if (event != null) {
					this.changeCurrentTableSort({
						stateVariable: this.stateVariable,
						event,
					});
				}

				this.getList(event);
			},
		},
		computed: {
			...mapState(["datatableDefaults"]),
			currentRouteName() {
				return this.$route.name;
			},
		},
		watch: {
			filters: {
				handler() {
					if (this.reloadByFilters) {
						clearTimeout(this.timeout);

						this.timeout = setTimeout(() => {
							this.getList();
						}, 250);
					}
				},
				deep: true,
			},
		},
		mounted() {
			this.getList();
		},
	};
</script>

<style lang="scss" scoped>
	.action-buttons {
		button {
			min-width: fit-content;
		}
	}

	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		:deep(.p-paginator-current) {
			text-align: center;
		}

		:deep(.select-multiple) {
			display: none !important;
		}

		.action-buttons {
			overflow-x: auto;
		}
	}
</style>
