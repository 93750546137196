<template>
	<footer class="custom-section-margin">
		<!-- Company details -->
		<div v-if="showAditionalInformation" class="bg-white">
			<div class="custom-container mx-auto py-5">
				<div class="row gy-5">
					<!-- Time -->
					<div class="col-12 col-md-6">
						<div class="row text-center text-md-start">
							<div class="col-8 col-md-12 mx-auto">
								<div
									class="border-bottom border-1 border-secondary title text-secondary"
								>
									Horario comercial
								</div>
							</div>
							<div class="col-12 col-md-6 mt-3">
								<div class="text-secondary">Papelería</div>
								<div class="d-flex flex-column mb-3">
									<span>Lunes - Viernes</span>
									<span>09:00 a 13:30</span>
									<span>16:00 a 19:45</span>
								</div>
								<div class="d-flex flex-column">
									<span>Sábados</span>
									<span>09:00 a 13:00</span>
								</div>
							</div>
							<div class="col-12 col-md-6 mt-3">
								<div class="text-secondary">Imprenta</div>
								<div class="d-flex flex-column mb-3">
									<span>Lunes - Viernes</span>
									<span>08:00 a 16:00</span>
								</div>
							</div>
						</div>
					</div>

					<!-- Deliveries -->
					<div class="col-12 col-md-6">
						<div class="row text-center text-md-start">
							<div class="col-8 col-md-12 mx-auto">
								<div
									class="border-bottom border-1 border-secondary title text-secondary"
								>
									Pedidos
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="text-secondary">Teléfonos</div>
								<div
									class="d-flex flex-column flex-md-row flex-wrap gap-2 align-items-center align-items-md-start"
								>
									<Phone
										color="dark"
										:showText="true"
										:showIcon="false"
										phoneNumber="phone-1"
									/>
									<span class="d-none d-md-block">-</span>
									<Phone
										color="dark"
										:showText="true"
										:showIcon="false"
										phoneNumber="phone-2"
									/>
									<span class="d-none d-md-block">-</span>
									<Phone
										color="dark"
										:showText="true"
										:showIcon="false"
										phoneNumber="phone-3"
									/>
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="text-secondary">Whatsapp</div>
								<div
									class="d-flex justify-content-center justify-content-md-start"
								>
									<Whatsapp
										color="dark"
										:showText="true"
										:showIcon="false"
									/>
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="text-secondary">Email</div>
								<Email color="dark" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Social media links -->
		<div class="social-media-container w-full p-4">
			<div class="custom-container mx-auto">
				<div
					class="d-flex justify-content-between align-items-center text-white gap-3"
				>
					<div>© 2024 Librería e Imprenta Rivero.</div>
					<div class="d-flex gap-3">
						<Facebook color="white" :showText="false" />
						<Whatsapp color="white" :showText="false" />
						<Instagram color="white" :showText="false" />
					</div>
				</div>
			</div>
		</div>

		<!-- Footer links -->
		<div class="custom-container mx-auto py-5 text-white">
			<div class="row gy-5">
				<!-- Company details -->
				<div class="col-12 col-md-3">
					<div
						class="d-flex flex-column gap-4 text-center text-md-start"
					>
						<div class="title">Librería e Imprenta Rivero S.L.</div>
						Llevamos más de 75 años ofreciendo servicios de
						papelería e imprenta a nuestros clientes.
					</div>
				</div>

				<!-- Page routes -->
				<div class="col-12 col-md-3">
					<div
						class="d-flex flex-column gap-4 text-center text-md-start"
					>
						<div class="title">Más sobre nosotros</div>
						<div class="d-flex flex-column gap-2">
							<router-link
								v-for="(pageRoute, index) in pageRoutes"
								:key="index"
								:to="pageRoute.href"
								class="link text-white"
							>
								{{ pageRoute.label }}
							</router-link>
						</div>
					</div>
				</div>

				<!-- Legal pages -->
				<div class="col-12 col-md-3">
					<div
						class="d-flex flex-column gap-4 text-center text-md-start"
					>
						<div class="title">Enlaces de interés</div>
						<div class="d-flex flex-column gap-2">
							<router-link
								v-for="(legalPage, index) in legalPages"
								:key="index"
								:to="legalPage.to"
								class="link text-white"
							>
								{{ legalPage.label }}
							</router-link>
						</div>
					</div>
				</div>

				<!-- Social media links -->
				<div class="col-12 col-md-3">
					<div
						class="d-flex flex-column gap-4 text-center text-md-start"
					>
						<div class="title">Contacta con nosotros</div>
						<div
							class="d-flex flex-column align-items-center align-items-md-start gap-2"
						>
							<Whatsapp color="white" :showText="true" />
							<Phone color="white" :showText="true" />
							<Phone
								color="white"
								:showText="true"
								phoneNumber="phone-2"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import { mapState } from "vuex";
	import Whatsapp from "@/components/web/partials/Whatsapp.vue";
	import Facebook from "@/components/web/partials/Facebook.vue";
	import Instagram from "@/components/web/partials/Instagram.vue";
	import Phone from "@/components/web/partials/Phone.vue";
	import Email from "@/components/web/partials/Email.vue";

	export default {
		components: {
			Whatsapp,
			Facebook,
			Instagram,
			Phone,
			Email,
		},
		data() {
			return {
				legalPages: [
					{
						label: "Política de privacidad",
						to: "/politica-de-privacidad",
					},
					{
						label: "Aviso legal",
						to: "/aviso-legal",
					},
					{
						label: "Política de cookies",
						to: "/politica-de-cookies",
					},
				],
			};
		},
		computed: {
			...mapState(["pageRoutes"]),
			showAditionalInformation() {
				return this.$route.name !== "contact";
			},
		},
	};
</script>

<style lang="scss" scoped>
	footer {
		background-color: darken($secondary, 10%);

		.social-media-container {
			background-color: $secondary;
		}

		.title {
			color: white;
			text-transform: uppercase;
			font-weight: bold;
			position: relative;
		}

		.link {
			text-decoration: none;
		}
	}
</style>
